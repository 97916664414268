.page-title-container{
	margin-top: 2.5vh;
	align-self: center;
}

.name{
	font-size: 25px;
	position: relative;
	text-decoration: none;
	font-family: var(--default-font);
	cursor: pointer;
	color: var(--default-text-color);
}

.name::before{
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: var(--default-text-color);
	transform: scaleX(0);
	transform-origin: top left;
	transition: transform 0.3s ease;
}

.name:hover::before {
	transform: scaleX(1);
}
