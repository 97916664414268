.projects-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: var(--default-font);
	color: var(--passive-text-color);
	line-height: 2;
	margin-top: 2.5vh;
}

.project-boxes{
	display: flex;
	flex-direction: column;
}

.grid{
	display: grid;
	grid-template-columns: auto;
}

@media screen and (orientation: portrait){
	.grid{
		grid-template-columns: auto;
		margin-top: 2.5vh;
	}
}
