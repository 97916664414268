.box{
	background-color: var(--box-theme);
	height: 20vh;
	width: 20vh;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items:center;
	justify-content:center;
	box-shadow: 3px 3px 3px 3px #00000008;
	cursor: pointer;
}

.box:hover{
	transform: scale(1.03);
	box-shadow: 8px 8px 3px 3px #00000010;
	transition: box-shadow 0.3s ease-in-out;
}

.text{
	text-align: center;
	position: relative;
	text-decoration: none;
	font-family: "Monaco", monospace;
	color: var(--default-text-color);
}

.text::before{
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: var(--default-text-color);
	transform: scaleX(0);
	transform-origin: top left;
	transition: transform 0.3s ease;
}

.text:hover::before {
  transform: scaleX(1);
}

@media screen and (orientation: portrait){
	.box{
		height: 10vh;
	}
}
