:root{
    --theme: #191919;
    --box-theme: #FFFFFF10;
    --default-text-color: white;
    --passive-text-color: #B2B2B2;
    --default-font: "Josefin Sans", monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

html, body{
    height: 100%;
    background-color: var(--theme);
}

.App {
    background-color: var(--theme);
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.themeToggle{
    width: 5vw;
}

.toggleImg{
    height: 1vw; 
    width: 1vw;
    padding-top: 5%;
}

.theme{
    height: 1vh;
}

.themeToggle{
    margin: 0.25vw;
    background-color: var(--box-theme);
    border: 0px;
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    overflow: visible;
}

@media screen and (orientation: portrait){
    .toggleImg{
        height: 3vw; 
        width: 3vw;
    }

    .themeToggle{
        margin: 1vw;
        width: 6vw;
        height: 6vw;
    }
}
