.about-container{
	display: flex;
	flex-direction: column;
	align-items:center;
	justify-content:center;
}

.about-sections{
	width: 60vw;
	align-items:center;
	justify-content:center;
	align-self: center;
}

.section-left{
	display: flex;
	flex-direction: row;
	justify-content:center;
	margin-top: 10vh;
}

.section-right{
	display: flex;
	flex-direction: row;
	justify-content:center;
	margin-top: 10vh;
}

.section-text{
	font-family: var(--default-font);
	color: var(--passive-text-color);
	line-height: 2;
	margin-left: 2vw;
	margin-right: 2vw;
}


.about-profile-pic{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	align-self: center;
	width: 10vw;
	height: 10vw;
	-webkit-box-shadow: 0 0 15px #0D98BA;
        box-shadow: 0 0 15px #0D98BA;
	border-radius: 50%;
	margin-left: center;
	margin-right: center;
}

.link-boxes{
	align-items:center;
	justify-content:center;
}

@media screen and (orientation: portrait){
	.section-right{
		flex-direction: column-reverse;
	}

	.section-left{
		flex-direction: column;
	}

	.about-profile-pic{
		width: 50vw;
		height: 50vw;
		margin-bottom: 2.5vh;
	}

	.section-image{
		align-self: center;
	}
}
